import { PropsWithChildren } from 'react';

interface IAppSearchOptionProps extends PropsWithChildren<any> {
  className: string;
}

const AppSearchOptionWrapper = ({ className, children }: IAppSearchOptionProps) => {
  return (
    <div
      className={`${className}  absolute mt-3 rounded-3xl border border-gray-200 bg-white px-8 py-4 shadow-xl`}
    >
      {children}
    </div>
  );
};

export default AppSearchOptionWrapper;
