import { useEffect, useRef, useState } from 'react';

import { useLocale } from 'next-intl';

import network from '../public/assets/stations/AutoFill.json';
import { Station } from '../utils/stations';
import { normalizeStationName } from '../utils/stationsUtils';

const DEBOUNCE_TIME = 200;
const debounce = (func, delay) => {
  let timerId;

  return (...args) => {
    clearTimeout(timerId);

    timerId = setTimeout(() => func(...args), delay);
  };
};

export const useStationAutoComplete = (locationName: string) => {
  const [displayedStations, setDisplayedStations] = useState<Station[]>([]);
  const locale = useLocale();
  const debouncedPickFromDatabase = useRef(
    debounce(async (_locationName: string) => {
      const matchingStations = await pickFromDatabase(_locationName);
      setDisplayedStations(matchingStations);
    }, DEBOUNCE_TIME)
  );

  useEffect(() => {
    const matchingStations = pickInTopStations(locationName, locale);

    if (matchingStations.length === 0) {
      debouncedPickFromDatabase.current(locationName);
    } else {
      setDisplayedStations(matchingStations);
    }
  }, [locale, locationName]);

  return displayedStations;
};

function pickInTopStations(locationName: string, locale: string): Station[] {
  const normalizedLocation = normalizeStationName(locationName);

  const matchingStations = network
    .filter((station) =>
      (station[`info_${locale}`]
        ? normalizeStationName(station[`info_${locale}`])
        : station.slug
      ).startsWith(normalizedLocation)
    )
    .sort((a, b) => b.population - a.population);
  return matchingStations;
}

async function pickFromDatabase(locationName: string): Promise<Station[]> {
  try {
    const response = await fetch(`/api/getStations?q=${locationName}`);
    const matchingStationsFromDb = await response.json();
    return matchingStationsFromDb;
  } catch (error) {
    console.log('Error happened on getStations call.');
    return [];
  }
}
