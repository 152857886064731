'use client';

import { useEffect, useState } from 'react';

import { defaultOriginName } from 'hooks/querySearch';
import { IExploreNearby } from 'typings';

import AppNearby from './AppNearby';

interface IAppNearbyProps {
  isSmall?: boolean;
  onClick?: () => void;
  numberOfItems?: number;
  exploreNearby: IExploreNearby[];
  changeOrigin?: boolean;
  exclude?: string;
  include: string[];
}

const AppNearbyList = ({
  onClick,
  exploreNearby,
  numberOfItems,
  changeOrigin,
  exclude,
  include,
}: IAppNearbyProps) => {
  const [filteredNearby, setFilteredNearby] = useState<IExploreNearby[]>([]);

  useEffect(() => {
    const result = exploreNearby
      .filter((data) => (exclude ? data.name !== exclude : true))
      .sort((a) => (include.includes(a.name) ? -1 : 1))
      .slice(0, numberOfItems);

    setFilteredNearby(result);
  }, [exploreNearby, include, exclude, numberOfItems]);

  if (!exploreNearby || exploreNearby.length === 0) {
    return null;
  }

  if (!changeOrigin) {
    exclude ??= defaultOriginName;
  }

  return filteredNearby.map((data) => (
    <AppNearby
      key={data.stationName}
      data={data}
      isSmall
      onClick={onClick}
      changeOrigin={changeOrigin}
    />
  ));
};

export default AppNearbyList;
