import { PropsWithChildren } from 'react';

import { ChevronLeftIcon } from '@heroicons/react/outline';
import { useTranslations } from 'next-intl';

import {
  useCheckIn,
  useCheckOut,
  useGuestAdults,
  useGuestBikes,
  useGuestChildren,
  useGuestInfants,
  useGuestYouths,
} from 'hooks/useQuerySearch';
import { formatRangeDate, formatGuests } from 'utils';

interface IAppSearchOptionWrapperMobileProps extends PropsWithChildren<any> {
  title: string;
  handleOnBack: () => void;
  haveNavigation: boolean;
}

const AppSearchOptionWrapperMobile = ({
  title,
  children,
  handleOnBack,
  haveNavigation,
}: IAppSearchOptionWrapperMobileProps) => {
  const [checkIn] = useCheckIn();
  const [checkOut] = useCheckOut();

  const [guestAdults] = useGuestAdults();
  const [guestYouths] = useGuestYouths();
  const [guestChildren] = useGuestChildren();
  const [guestInfants] = useGuestInfants();
  const [guestBikes] = useGuestBikes();

  const guests = { guestAdults, guestYouths, guestChildren, guestInfants, guestBikes };

  const t = useTranslations('Header.search');

  return (
    <div className={`${haveNavigation && 'mb-16'} rounded-t-2xl bg-white px-4 py-6`}>
      <div className="mb-5 flex h-7 items-center">
        <button
          id="button-back-search"
          className="absolute rounded-full border border-gray-200 p-[5px]"
          onClick={handleOnBack}
        >
          <ChevronLeftIcon className="h-6 " />
        </button>
        <div className="flex w-full flex-col justify-center text-center">
          <h3 className="font-medium">{title}</h3>
          {checkIn && checkOut && (
            <span className="text-xs text-gray-300">
              {formatRangeDate(checkIn, checkOut)}
              {formatGuests(guests, t) ? ` • ${formatGuests(guests, t)}` : ''}
            </span>
          )}
        </div>
      </div>
      <div>{children}</div>
    </div>
  );
};

export default AppSearchOptionWrapperMobile;
