import { SearchIcon } from '@heroicons/react/outline';
import { useTranslations, useLocale } from 'next-intl';

import { useCheckIn, useDestinationName, useOriginName } from 'hooks/useQuerySearch';
import { ESearchMenu } from 'interfaces';
import { formatCheckDate } from 'utils/datesUtils';

interface IClosedSearchBarProps {
  isActiveSearch: boolean;
  setIsActiveSearch: (value: boolean) => void;
  setSearchMenu: (value: any) => void;
}

const ClosedSearchBar = ({
  isActiveSearch,
  setIsActiveSearch,
  setSearchMenu,
}: IClosedSearchBarProps) => {
  const locale = useLocale();
  const t = useTranslations('Header');

  const [originName] = useOriginName();
  const [destinationName] = useDestinationName();
  const [checkIn] = useCheckIn();

  return (
    <div
      className={`${
        isActiveSearch && 'z-[-50] translate-y-[75px] scale-[1.33] opacity-0'
      } relative left-24 mx-auto hidden h-12 min-w-[320px] transform cursor-pointer items-center rounded-full border border-gray-200 bg-white pl-2 pr-2 text-left shadow-md duration-200 hover:shadow-lg md:absolute md:left-auto md:right-1/2 md:flex md:translate-x-1/2`}
    >
      <div className="flex w-full flex-row items-center justify-start">
        <button
          id="closed-search-from"
          className=" border-gray-200 max-w-40 flex flex-grow flex-col rounded-l-2xl border-r-[1.5px] pl-3 pr-4  hover:bg-gray-100"
          onClick={() => {
            setIsActiveSearch(true);
            setSearchMenu(ESearchMenu.ORIGIN);
          }}
        >
          <div className="flex w-full">
            <div className="min-w-10 -mb-0.5 flex">
              <span className="text-xs text-gray-300">{t('search.from.title')}</span>
            </div>
          </div>
          <div className="flex w-full" aria-hidden="true">
            <span className="whitespace-nowrap text-ellipsis overflow-hidden font-semibold">
              {originName || t('search.from.default')}
            </span>
          </div>
        </button>

        <button
          id="closed-search-to"
          onClick={() => {
            setIsActiveSearch(true);
            setSearchMenu(ESearchMenu.DESTINATION);
          }}
          className="min-w-24 flex flex-col max-w-40 pl-3 pr-5 hover:bg-gray-100 border-r-[1.5px] border-gray-200"
        >
          <div className="flex">
            <div className="min-w-10 -mb-0.5 flex">
              <span className="text-xs text-gray-300">{t('search.to.title')}</span>
            </div>
          </div>
          <div className="flex" aria-hidden="true">
            <span className="font-semibold whitespace-nowrap overflow-hidden text-ellipsis">
              {destinationName || t('search.to.default')}
            </span>
          </div>
        </button>

        <button
          id="closed-search-checkin"
          onClick={() => {
            setIsActiveSearch(true);
            setSearchMenu(ESearchMenu.CHECK_IN);
          }}
          className="min-w-20 flex flex-col pl-3 pr-5 hover:bg-gray-100"
        >
          <div className="flex">
            <div className="min-w-10 -mb-0.5 flex">
              <span className="text-xs text-gray-300">{t('search.checkIn.title')}</span>
            </div>
          </div>
          <div className="flex" aria-hidden="true">
            <div>
              <span className=" font-semibold">
                {formatCheckDate(checkIn, locale) || t('search.checkIn.default')}
              </span>
            </div>
          </div>
        </button>
      </div>

      <SearchIcon
        onClick={() => {
          setIsActiveSearch(true);
          setSearchMenu(ESearchMenu.ORIGIN);
        }}
        className="w-9 rounded-full bg-primary p-2 text-white"
      />
    </div>
  );
};
export default ClosedSearchBar;
