import moment from 'moment';

import prisma from '../../utils/prisma';

export const PRICE_UNKNOWN_CONST = 1234567;

const pickBestFaresPerMode = (fares) => {
  return fares.reduce((accumulator, currentFare) => {
    const date = moment(currentFare.departure).format('YYYY-MM-DD');
    accumulator[date] = accumulator[date] || {};

    if (
      !accumulator[date][currentFare.mode] ||
      currentFare.updatedAt > accumulator[date][currentFare.mode].updatedAt
    ) {
      accumulator[date][currentFare.mode] = currentFare;

      if (
        !accumulator[date]['all'] ||
        currentFare.amount < accumulator[date]['all'].amount
      ) {
        accumulator[date]['all'] = currentFare;
      }
    }

    return accumulator;
  }, {});
};

const pickBestPricePerMode = (fares) => {
  const bestPrice = {};
  const bestFares = pickBestFaresPerMode(fares);

  for (const date in bestFares) {
    bestPrice[date] = {};

    for (const mode in bestFares[date]) {
      const fare = bestFares[date][mode];
      bestPrice[date][mode] = fare.amount;
    }
  }
  return bestPrice;
};

export default async function handler(req, res) {
  const { startStationId, endStationId, firstdate, lastdate } = req.query;
  try {
    const fares = await prisma.fare.findMany({
      where: {
        DepartureStation: parseInt(startStationId),
        ArrivalStation: parseInt(endStationId),
        departure: {
          gte: firstdate,
          lte: lastdate,
        },
      },
      select: {
        departure: true,
        amount: true,
        mode: true,
        duration: true,
        updatedAt: true,
      },
    });

    const result = pickBestPricePerMode(fares);
    res.status(200).json({ ...result });
  } catch (err) {
    console.error(`Error fetching fares: ${err}`);
    res.status(500).json({ message: 'Error occurred.' });
  }
}
