// icons
import { XIcon } from '@heroicons/react/outline';

interface IAppClearButtonProps {
  active: boolean;
  isFocus?: boolean;
  separator?: boolean;
  onClick: () => void;
}

const AppClearButtonProps = ({
  onClick,
  active,
  isFocus = true,
  separator = false,
}: IAppClearButtonProps) => {
  //TOFIX instead of hidding the button

  const HideButton = 'hidden';
  return (
    <div
      className={`${separator && 'border-r border-gray-200'} h-8 z-20 items-center flex`}
    >
      <div
        role="button"
        tabIndex={0}
        className={`${HideButton} ${
          active && isFocus ? 'opacity-100' : 'hidden cursor-text'
        } flex items-center pr-3`}
        onClick={onClick}
      >
        <XIcon className="h-6 rounded-full bg-gray-200 bg-opacity-60 p-1 hover:bg-opacity-100" />
      </div>
    </div>
  );
};

export default AppClearButtonProps;
