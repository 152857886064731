import { fr, enUS as en, de } from 'date-fns/locale';
import { useLocale } from 'next-intl';
import { Calendar } from 'react-date-range';

import { useCheckIn } from 'hooks/useQuerySearch';

interface IAppCalendarProps {
  months?: number;
  handle?: (range: any) => void;
  className?: string;
  disabledDates?: Date[];
  dayContent?: (date: Date) => JSX.Element;
}

const DEFAULT_NUMBER_OF_MONTHS = 2;

const millisecondsInADay = 1000 * 60 * 60 * 24;

const today = new Date();
const in90Days = new Date(today.getTime() + 120 * millisecondsInADay);

const AppCalendar = ({
  months,
  handle,
  className,
  disabledDates,
  dayContent,
}: IAppCalendarProps) => {
  const [checkIn, setCheckIn] = useCheckIn();
  const locale = useLocale();

  const selectedDate = checkIn;

  const handleDatePicker = async (date) => {
    await setCheckIn(date);
    handle && handle(date);
  };

  return (
    <div className={`rounded-3xl md:py-4 ${className}`}>
      <Calendar
        date={selectedDate}
        onChange={handleDatePicker}
        months={months || DEFAULT_NUMBER_OF_MONTHS}
        direction="horizontal"
        showMonthAndYearPickers={false}
        rangeColors={['#FFFFFF']}
        minDate={today}
        showDateDisplay={false}
        monthDisplayFormat="MMMM YYY"
        locale={locale == 'fr' ? fr : locale == 'en' ? en : de}
        maxDate={in90Days}
        disabledDates={disabledDates}
        dayContentRenderer={dayContent}
      />
    </div>
  );
};

export default AppCalendar;
