import { MinusIcon, PlusIcon } from '@heroicons/react/outline';

interface IAppCounterProps {
  value: number;
  setValue: (value: number) => Promise<URLSearchParams>;
  maxValue: number;
}

const AppCounter = ({ value, setValue, maxValue }: IAppCounterProps) => {
  const onIncrease = async () => {
    if (value < maxValue) {
      await setValue(value + 1);
    }
  };
  const onDecrease = async () => {
    if (value > 0) {
      await setValue(value - 1);
    }
  };
  return (
    <div className="flex items-center">
      <span
        role="button"
        tabIndex={0}
        className={`${
          !value && 'cursor-not-allowed opacity-40'
        } btnDecrease inline-block rounded-full border border-gray-500 border-opacity-70 p-[7px] outline-none duration-300 active:scale-90`}
        onClick={onDecrease}
      >
        <MinusIcon className="h-4 text-gray-500" />
      </span>
      <div className="mx-2 flex h-9 w-9 items-center justify-center rounded-full bg-primary text-center font-medium text-white">
        {value}
      </div>
      <span
        role="button"
        tabIndex={0}
        className={`${
          value === maxValue && 'cursor-not-allowed opacity-40'
        } btnIncrease inline-block rounded-full border border-gray-500 border-opacity-70 p-[7px] outline-none duration-300 active:scale-90`}
        onClick={onIncrease}
      >
        <PlusIcon className="h-4 text-gray-500" />
      </span>
    </div>
  );
};

export default AppCounter;
