import { Leg } from 'utils/journey';

export interface Price {
  id: string;
  amount: number;
  departure: string;
  arrival: string;
  duration: number;
  origin: string;
  destination: string;
  legs: Leg[];
  mode: string;
  operator: string;
}

export enum ESearchMenu {
  ORIGIN = 'origin',
  DESTINATION = 'destination',
  CHECK_IN = 'checkIn',
  CHECK_OUT = 'checkOut',
  GUESTS = 'guests',
}
