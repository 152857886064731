import { ChangeEvent, FocusEvent, PropsWithChildren, useRef } from 'react';

// components
// icons
import { SearchIcon } from '@heroicons/react/outline';
//translations
import { useTranslations } from 'next-intl';

import AppClearButtonProps from '@/components/atoms/AppClearButton';
interface IAppSearchOptionButtonProps<T> extends PropsWithChildren<any> {
  relative?: boolean;
  withSearch?: boolean;
  separator?: boolean;
  isSearch?: boolean;
  type?: string;
  id: string;
  title: string;
  placeholder: string;
  active: boolean;
  value: T;
  onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  onFocus: () => void;
  onBlur: (event: FocusEvent<HTMLElement>) => void;
  onClear: () => void;
}

const AppSearchOptionButton = <T extends string | false>({
  relative,
  children = 0,
  separator,
  withSearch,
  isSearch,
  type,
  id,
  title,
  placeholder,
  active,
  value,
  onChange,
  onFocus,
  onBlur,
  onClear,
}: IAppSearchOptionButtonProps<T>) => {
  //wait for component to load and select input 'text-box'

  const inputRef = useRef<HTMLInputElement>(null);
  const t = useTranslations('Header.search');
  const handleFocus = () => {
    if (type === 'inputText' && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.select();
    }
    onFocus();
  };

  return (
    <span
      role="button"
      tabIndex={0}
      className={`
        ${
          active
            ? 'bg-white shadow-arround shadow-gray-400'
            : 'hover:bg-gray-300 hover:bg-opacity-40'
        } ${relative && 'relative'} flex items-center rounded-full h-full flex-grow`}
      onFocus={handleFocus}
      onBlur={onBlur}
    >
      <div
        className={`${withSearch && 'min-w-[120px]'} flex flex-grow flex-col pl-7 pr-7 text-left`}
      >
        <span className="text-xs font-bold tracking-wider text-gray-600">{title}</span>
        {type === 'inputText' ? (
          <input
            ref={inputRef}
            autoComplete="off"
            autoCorrect="off"
            dashlane-ignored="true"
            data-dashlane-disabled-on-field="true"
            data-kwimpalastatus="dead"
            data-form-type="address,city"
            spellCheck="false"
            id={`text-box-${id}`}
            type="text"
            value={value ? value : ''}
            onChange={onChange}
            onFocus={onFocus}
            placeholder={placeholder}
            className="w-full truncate bg-transparent text-sm font-medium text-gray-500 placeholder-gray-300 outline-none"
          />
        ) : (
          <span className="max-w-[105px] truncate text-sm font-medium text-gray-500 lg:max-w-none">
            {value || placeholder}
          </span>
        )}
      </div>

      <AppClearButtonProps
        onClick={onClear}
        active={!!value}
        isFocus={active}
        separator={separator}
      />

      {withSearch && (
        <button
          type="submit"
          id="button-gtm-search"
          className={`${isSearch ? 'w-auto saturate-200' : 'w-12'} m-2 ml-0 flex h-12 items-center justify-center rounded-full bg-primary px-3 hover:saturate-200`}
        >
          <SearchIcon className="h-5 text-white" />
          <span
            id="span-gtm-search"
            className={`${isSearch ? 'inline-block' : 'hidden'} ml-2 font-medium text-white`}
          >
            {t('button')}
          </span>
        </button>
      )}
      <div className={`${active ? 'block' : 'hidden'} mt-16`}>{children}</div>
    </span>
  );
};

export default AppSearchOptionButton;
