import { orderBy } from 'lodash';
import { DateTime } from 'luxon';

import { PRICE_UNKNOWN_CONST } from 'pages/api/getDailyFares';

import { Journey, Leg } from './journey';
import { LightFare, PricedStation } from './stations';

const Best = {
  amount: 'cheapest',
  duration: 'fastest',
};
const SORTING_VALUES = ['amount', 'duration', 'departure', 'connections'] as const;

export type SortingType = (typeof SORTING_VALUES)[number];

export const DEFAULT_SORTING = ['amount', 'connections'] as SortingType[];

function getSortFunction(sortBy: SortingType) {
  switch (sortBy) {
    case 'departure':
      return (journey: Journey) => DateTime.fromISO(journey.departure);
    case 'connections':
      return (journey: Journey) => journey.legs.length;
    default:
      return (journey: Journey) => journey[sortBy];
  }
}

export function sortJourneys(
  journeys: Journey[] | null,
  sortBy: SortingType[],
  order: boolean | 'asc' | 'desc' = 'asc'
) {
  if (journeys) {
    const hasUnavailableLeg = (journey: Journey) => {
      return journey.legs.some((leg) => leg?.unavailable?.yes == true);
    };

    const sortedJourneys = orderBy(journeys, sortBy.map(getSortFunction), order);

    const availableJourneys = sortedJourneys.filter(
      (journey) => !hasUnavailableLeg(journey)
    );
    const unavailableJourneys = sortedJourneys.filter(hasUnavailableLeg);

    return [...availableJourneys, ...unavailableJourneys];
  } else {
    return [];
  }
}

function filterJourneys(
  journeys: Journey[],
  transportMode: string,
  nightTrainMode: boolean
) {
  if (nightTrainMode) {
    return journeys.filter((journey) => journey.nighttrain && journey.mode === 'train');
  }
  if (transportMode === 'all') {
    return journeys;
  }
  return journeys.filter((journey) => journey.mode === transportMode);
}

function labelByRank(sorted, key) {
  let i = 0;

  while (i < sorted.length && sorted[i][key] === PRICE_UNKNOWN_CONST) {
    i++;
  }

  if (i == sorted.length) {
    return;
  }

  const bestValue = sorted[i][key];

  while (i < sorted.length && sorted[i][key] === bestValue) {
    sorted[i].value =
      sorted[i].value.length >= 1 ? [sorted[i].value, Best[key]] : Best[key];
    i++;
  }
}

export function filterAndLabelJourneys(
  journeys: Journey[],
  transportMode: string,
  nightTrainMode: boolean
) {
  const filtered = filterJourneys(journeys, transportMode, nightTrainMode);

  if (filtered.length === 0) {
    return filtered;
  }

  let sorted = orderBy(filtered, ['amount'], ['asc']);

  // We need to flush previous results to ensure everything goes well on rerenders
  for (const offer of sorted) {
    offer.value = [];
  }
  labelByRank(sorted, 'amount');
  sorted = orderBy(sorted, ['duration'], ['asc']);
  labelByRank(sorted, 'duration');

  return sorted;
}

export function getCheapestJourney(
  journeys: PricedStation,
  transportMode: string,
  nightTrainMode: boolean,
  maxNumberConnection?: number
) {
  let journeyFilter: (price: LightFare) => boolean;

  if (nightTrainMode) {
    journeyFilter = (price) => price.mode == 'nightTrain';
  } else if (transportMode === 'all') {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    journeyFilter = (_) => true;
  } else {
    journeyFilter = (price) => transportMode === price.mode;
  }

  let selectedJourneys = journeys.prices.filter(journeyFilter);

  const segmentCountFilter = (price: LightFare) =>
    price.segmentCount !== undefined && price.segmentCount <= maxNumberConnection! + 1;
  if (maxNumberConnection !== undefined) {
    selectedJourneys = selectedJourneys.filter(segmentCountFilter);
  }
  const bestPrice = Math.min(...selectedJourneys.map((price) => price.amount));
  const cheapestJourneys = selectedJourneys.filter((price) => price.amount === bestPrice);

  // We pick the first in the list for now we might want to select the quickest for example
  return cheapestJourneys?.[0];
}

export function formatPrice(
  amount: number | undefined,
  unknownIsFree = false,
  unknownIsnt = false
) {
  if (!amount || amount === Infinity || amount === PRICE_UNKNOWN_CONST) {
    if (unknownIsFree) {
      return 'Free';
    } else if (unknownIsnt) {
      return '-';
    }
    return '- €';
  }
  return `${Math.ceil(amount)}€`;
}

export const countTrainTravelLegs = (legs: Leg[]) =>
  legs.filter((leg) => leg.type == 'TRAIN_TRAVEL' || leg.type == null).length;
