import { IGuestCount } from 'typings';

interface IFormatGuestOptions {
  noInfants?: boolean;
}

export const formatGuests = (guests: IGuestCount, t, options?: IFormatGuestOptions) => {
  if (!guests) {
    return false;
  }
  const { noInfants } = options || {};
  const {
    guestAdults,
    guestYouths,
    guestChildren,
    guestSeniors,
    guestInfants,
    guestBikes,
  } = guests;
  const total =
    guestAdults + guestChildren + guestYouths + (guestSeniors ?? 0) + (guestInfants ?? 0);
  if (!total) {
    return '0';
  }

  let guestList = [] as string[];

  if (guestAdults) {
    guestList.push(formatSingleGuestType(guestAdults, 'adults', t));
  }
  if (guestYouths) {
    guestList.push(formatSingleGuestType(guestYouths, 'youths', t));
  }
  if (guestChildren) {
    guestList.push(formatSingleGuestType(guestChildren, 'children', t));
  }
  if (guestSeniors) {
    guestList.push(formatSingleGuestType(guestSeniors, 'seniors', t));
  }
  if (guestInfants && !noInfants) {
    guestList.push(formatSingleGuestType(guestInfants, 'infants', t));
  }
  if (guestBikes) {
    guestList.push(formatSingleGuestType(guestBikes, 'bikes', t));
  }

  let formattedGuests = guestList.join(', ');

  return formattedGuests;
};

function formatSingleGuestType(guestCount: number, guestType: string, t) {
  return `${guestCount}  ${t(`travelers.${guestType}.autoPlural`, { count: guestCount })}`;
}
