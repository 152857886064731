export const utf8_to_latin1 = (str: string | null) => {
  if (str === null) {
    return '';
  }
  try {
    return decodeURIComponent(escape(str));
  } catch (e) {
    return str;
  }
};

export const normalizeStationName = (stationName: string) => {
  return utf8_to_latin1(stationName)
    .toLowerCase()
    .normalize('NFD')
    .replace(/[\u0300-\u036f]/g, '');
};
