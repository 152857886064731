'use client';

import { useEffect, useState } from 'react';

import Image from 'next/image';
import Link from 'next/link';
import { User } from 'next-auth';
import { useSession } from 'next-auth/react';
import { useLocale, useTranslations } from 'next-intl';

import AppLogo from '@/components/atoms/AppLogo';
import AppSearchBar from '@/components/molecules/AppSearchBar';
import AppSearchBarMobile from '@/components/molecules/AppSearchBarMobile';
import { useRedirectUrl } from 'context/redirectUrlProvider';
import { ESearchMenu } from 'interfaces';
import { EHeaderOptions, IExploreNearby } from 'typings';

import AppFeedback from '../atoms/AppFeedback';
import { AppLogoType } from '../atoms/AppLogoType';
import LanguageSelection from '../atoms/LanguageSelection';
import ClosedSearchBar from '../molecules/ClosedSearchBar';
import { UserAccountNav } from '../molecules/UserAccountNav';
import { LoginModal } from '../account/LoginModal';

interface AppHeaderProps {
  exploreNearby?: IExploreNearby[];
  largeSearchBarByDefault?: boolean;
  landingPage?: boolean;
  hideSearch?: boolean;
  background?: string;
  menuType?: EHeaderOptions;
}

const AppHeader = ({
  exploreNearby,
  largeSearchBarByDefault,
  hideSearch = false,
  landingPage,
  background,
  menuType = EHeaderOptions.STATION_TO_STATION,
}: AppHeaderProps) => {
  const [isSnapTop, setIsSnapTop] = useState<boolean>(landingPage ?? false);
  const [isActiveSearch, setIsActiveSearch] = useState<boolean>(landingPage ?? false);
  const [searchMenu, setSearchMenu] = useState<ESearchMenu | null>(null);
  const [activeMenu] = useState<EHeaderOptions>(menuType);
  const session = useSession()?.data;
  const isLoggedIn = session ? true : false;
  const [, , setRedirectUrlToCurrentPage] = useRedirectUrl();
  const [showLogin, setShowLogin] = useState<boolean>(false);

  const user = session?.user as User;
  const [userLevel, setUserLevel] = useState<number>(1);

  const t = useTranslations('Header');
  useLocale();

  const headerBehavior = () => {
    const style: string[] = [];
    if (!isSnapTop && !hideSearch) {
      style.push('bg-white shadow-md sm:shadow-lg');
    }
    if (isActiveSearch) {
      style.push('pb-4 md:pb-8');
    } else {
      if (landingPage) {
        style.push('pb-4 h-[86px]');
      } else {
        style.push('pb-4 md:h-[86px]');
      }
    }
    return style.join(' ');
  };

  const isDesktop = typeof window !== 'undefined' && window.innerWidth > 768;

  const scrollHasSomeEffect =
    isDesktop && !hideSearch && (landingPage || largeSearchBarByDefault);
  useEffect(() => {
    if (scrollHasSomeEffect) {
      const handleOnScroll = () => {
        const position = window.scrollY;

        if (position >= 142) {
          setIsSnapTop(false);
          setIsActiveSearch(false);
        } else {
          setIsSnapTop(true);
          setIsActiveSearch(true);
        }
      };

      window.addEventListener('scroll', handleOnScroll);
      return () => window.removeEventListener('scroll', handleOnScroll);
    }
  }, [scrollHasSomeEffect, isSnapTop, isActiveSearch]);

  return (
    <>
      <header
        className={`${headerBehavior()} ${!landingPage && !hideSearch && 'z-50'} ${landingPage && 'bg-purpple-background'} ${background} fixed top-0 z-10 w-full pt-2 md:pt-5 duration-300 md:z-50 md:transition-none`}
      >
        {/* header top */}
        <div
          className={`${largeSearchBarByDefault ? 'hidden md:grid' : ''} max-w-screen-2xl w-full mx-auto px-8 grid grid-cols-[auto,1fr,auto] items-center xl:grid-cols-[1.5fr,3fr,1.5fr] 2xl:grid-cols-[1fr,3fr,1fr]`}
        >
          {/* left side - logo */}
          <div className="flex h-12 items-center">
            <Link
              href="/"
              className={` ${!isSnapTop && 'hover:bg-gray-300'} rounded-full bg-opacity-25 pr-1 backdrop-blur-sm hover:bg-opacity-30 ${landingPage ? 'bg-transparent' : 'bg-white'}`}
            >
              <AppLogo className="text-primary" type={AppLogoType.TEXT} />
            </Link>
          </div>
          {/* small search bar */}
          {!hideSearch && (
            <ClosedSearchBar
              setIsActiveSearch={setIsActiveSearch}
              isActiveSearch={isActiveSearch}
              setSearchMenu={setSearchMenu}
            />
          )}
          {/* middle side navigation */}
          <div className="relative order-last col-span-3 flex flex-col items-center justify-center xl:order-none xl:col-span-1">
            <div className="text-white"></div>
          </div>
          {/* right side */}
          <div className="flex items-center justify-end gap-1.5 self-center max-sm:-mr-2.5">
            <div className="hidden lg:flex">
              <AppFeedback
                text={t('feedback.title')}
                landingPage={landingPage}
                user={user}
              />
            </div>
            <LanguageSelection />
            <UserAccountNav
              user={user}
              userLevel={userLevel}
              setUserLevel={setUserLevel}
            />
          </div>
        </div>
        {!hideSearch && (
          <>
            <AppSearchBar
              menu={activeMenu}
              searchMenu={searchMenu}
              setSearchMenu={setSearchMenu}
              exploreNearby={exploreNearby ?? []}
              isActiveHeader={isActiveSearch}
              closeSearchOnSubmit={!landingPage}
              setIsActiveSearch={setIsActiveSearch}
            />
            <AppSearchBarMobile
              activeMenu={activeMenu}
              exploreNearby={exploreNearby ?? []}
              searchPage={!landingPage}
            />
          </>
        )}
      </header>

      {landingPage && !isLoggedIn && (
        <div
          className={`hidden md:flex z-20 fixed cursor-pointer group flex-col transition-transform shadow-md -top-24 left-20 w-40 h-60 bg-white -rotate-6 rounded-3xl hover:translate-y-12 hover:rotate-0 `}
        >
          <div className="h-2/3 pb-5 pt-1 items-center flex flex-col flex-end justify-center">
            <Image
              src="https://assets-global.website-files.com/625593a881b8ebd169835ca5/65401f6d040a7e7810ad0188_dsfgkjh.svg"
              loading="lazy"
              alt=""
              className="w-36"
              height={100}
              width={100}
            />
            <div className="text-secondary text-center text-slate-600">
              {t('beta.subtitle')}
            </div>
          </div>
          <div className="w-full h-3 gap-2 justify-center flex relative px-1.5 -mb-1.5">
            <div className="w-2 h-2 bg-white rounded-full"></div>
            <div className="w-2 h-2 bg-white rounded-full"></div>
            <div className="w-2 h-2 bg-white rounded-full"></div>
            <div className="w-2 h-2 bg-white rounded-full"></div>
            <div className="w-2 h-2 bg-white rounded-full"></div>
            <div className="w-2 h-2 bg-white rounded-full"></div>
            <div className="w-2 h-2 bg-white rounded-full"></div>
            <div className="w-2 h-2 bg-white rounded-full"></div>
            <div className="w-2 h-2 bg-white rounded-full"></div>
            <div className="w-2 h-2 bg-white rounded-full"></div>
            <div className="w-2 h-2 bg-white rounded-full"></div>
            <div className="w-2 h-2 bg-white rounded-full"></div>
          </div>

          <button
            id="button-beta-login"
            className="w-full h-20 rounded-b-3xl bg-orange text-lg text-white font-medium"
            onClick={() => {
              setRedirectUrlToCurrentPage();
              setShowLogin(true);
            }}
          >
            <p className="group-hover:hidden">{t('beta.title')}</p>
            <p className="hidden group-hover:block">{t('beta.hovered')}</p>
          </button>
        </div>
      )}
      {/* background layer */}
      {isActiveSearch && !isSnapTop && (
        <div
          className="bg-transparent-black fixed inset-0 z-40"
          onClick={() => setIsActiveSearch(false)}
        />
      )}
      {showLogin && <LoginModal onClose={() => setShowLogin(false)} />}
    </>
  );
};

export default AppHeader;
